import React from 'react'
import PropTypes from 'prop-types'
import ConfirmLayout from 'src/templates/confirm'

// Styles & Images
import RampIcon from 'static/confirm/images/ramp--icon.svg'

// Main component:
const FiatRampRedirectPage = ({ location }) => {
  const deepLinkPath = `fiat-ramp${location.search}`
  return (
    <ConfirmLayout
      icon={RampIcon}
      iconSize="largest"
      page="fiat-ramp"
      deepLinkPath={deepLinkPath}
      location={location}
      backgroundGradient
    />
  )
}

// Main component props:
FiatRampRedirectPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}

FiatRampRedirectPage.defaultProps = {}

export default FiatRampRedirectPage
